import React from "react";
import { graphql } from "gatsby";
import LayoutContainer from "../containers";
import { Container, GraphQLError, SEO } from "../components/elements";
import BlogPost from "../components/BlogPost";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";
import { toPlainText } from "../helpers";

export const query = graphql`
  query GenericPageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      keywords
      curatedLinks {
        _key
        reference {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const GenericPage = ({ data, errors }) => {
  const {
    _rawBody: body,
    _rawExcerpt: excerpt,
    title,
    mainImage,
    publishedAt,
    curatedLinks,
    keywords = [],
  } = data.page;

  const pageKeywords = [title, ...keywords];

  useFirebaseAnalytics(`PAGE: ${title}`);
  return (
    <LayoutContainer>
      {errors && <SEO title="GraphQL Error" />}
      {data.page && (
        <SEO
          title={title || "Untitled"}
          description={toPlainText(excerpt)}
          image={mainImage}
          keywords={pageKeywords}
        />
      )}

      {errors && (
        <Container>
          <GraphQLError errors={errors} />
        </Container>
      )}

      {data.page && (
        <BlogPost
          body={body}
          title={title}
          mainImage={mainImage}
          publishedAt={publishedAt}
          curatedLinks={curatedLinks}
        />
      )}
    </LayoutContainer>
  );
};

export default GenericPage;
